<template>
  <div>
    <!-- 하단 앱다운로드 팝업 -->
    <div v-if="showAppDownloadPopup">
      <div class="app_down" @touchstart="startMove" @touchmove="move" @touchend="endMove">
        <div class="bt_wclose" @click.prevent="closeAppDownloadPopup()"></div>
        <div class="">
          <div class="down_img">
            <img src="../static/mobile/images/kway_app_down.png" />
          </div>
          <p class="title">APP 첫 로그인 3천원 웰컴 쿠폰 혜택</p>
          <p class="con">K-WAY APP 설치 후 더 나은 쇼핑을 경험해보세요.<br />PUSH 동의 시 쿠폰, 할인 등 다양한 혜택을 드립니다.</p>
          <a :href="appDownloadURL"><div class="bt_rwhite app_view">앱으로 보기</div></a>
          <button class="bt_app_next" @click.prevent="closeAppDownloadPopup()">다음에 설치할게요</button>
        </div>
      </div>
      <div class="dimmed_m_down"></div>
    </div>
    <!-- //하단 앱다운로드 팝업 -->
    <component :is="component"/>
  </div>
  
</template>

<script>
import Desktop from "@/components/layouts/device/Desktop.vue";
import Mobile from "@/components/layouts/device/Mobile.vue";
import Login from "@/assets/mixins/login";
import * as common from '@/assets/libs/common';
import { v4 as uuidv4 } from "uuid";

const isLive = process.env.IS_LIVE == "true";

export default {
  components: {Desktop, Mobile},

  mixins: [Login],

  middleware: ["user-info", "category"],

  data() {
    return {
      showAppDownloadPopup: false,
      userProfile: {
        userName: null,
        userId: null,
        email: null,
      },
      lastFromPage: "",
      lastPage:"",
      startX: 0,
      startY: 0,
      currentX: 0,
      currentY: 0
    };
  },

  async fetch({ $axios, store }) {
    let site = await $axios.$get("/api/site");
    store.commit("session/updateSiteInfo", site);
  },

  head() {
    return {
      meta: [
        {
          hid: "viewport",
          name: "viewport",
          content: `${this.viewport}`
        }
      ],
      __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
      // script: [
      //   {
      //     type: 'text/javascript',
      //     innerHTML:`
      //     window.cremaAsyncInit = function () {
      //     crema.init(${this.userId}, ${this.userName});
      //   };
      //     `
      //   }
      // ],
    }
  },

  computed: {
    component() {
      if(this.$route.path == '/criteo'){
        return "blank"
      }
      return this.$device.isMobile ? "mobile" : "desktop";
    },

    viewport() { 
      if (this.$device.isTablet) { 
        //console.log("is Tablet");
        return "width=1440";
      } else {
        //console.log("is Web or Mobile");
        // return "user-scalable=no,viewport-fit=auto,initial-scale=1,minimum-scale=1,maximum-scale=1,width=device-width,target-densitydpi=device-dpi";
        return "viewport-fit=auto,initial-scale=1,minimum-scale=1,maximum-scale=3,width=device-width,target-densitydpi=device-dpi";
      }
    },

    appDownloadURL() {
      if (!this.$store.state.layout.isApp) {
        if (this.$device.isAndroid) {
          return "https://play.google.com/store/apps/details?id=m.lottegfr.kway";
        } else if (this.$device.isIos) {
          //return "itms-apps://itunes.apple.com/app/1556789718";
          return "https://apps.apple.com/us/app/kway-%EA%B9%8C%EC%9B%A8/id1614040143";
        }
      }
      return undefined;
    },
    userName() {
      // return this.userProfile.userName?'"'+this.userProfile.userName+'"':null
      let session = this.$store.state.session;
      if (session.isMember) {
        return `"${session.profile.name}"`
      } else {
        return null
      }

    },
    userId() {
      // return this.userProfile.userId?'"'+this.userProfile.userId+'"':null
      let session = this.$store.state.session;
      if (session.isMember) {
        return `"${session.profile.loginId}"`
      } else {
        return null
      }
    }
  },

  async created() {
    try {
      if (this.$store.state.session.isEmpty) {
        let profile = await this.$axios.$get("/api/user/me");
        this.$store.commit("session/set", profile);
      }
    } catch (e) {
      //console.error(e);
    }

    if (this.$store.state.session.isMember) {
      await this.$_wish.get();
    }

    this.$store.commit("session/updateSiteInfo", await this.$axios.$get("/api/site"));
  },

  mounted() {

    if (process.client && window) {
      // window._isDebug = true // sso lib debug

      const objData = window.sessionStorage.getItem('lMembersTknObj')
      if (objData) {
        this.$store.commit("session/setLMembersTkn", JSON.parse(objData));
      }
    }

    try {
      let appNo = window.skyDroid.getAppNo();
      this.$store.commit("layout/setAppNo", {
        isAndroid: true,
        isApple: false,
        appNo
      });
    } catch (e) {
      //console.error(e);
    }
    console.log("this.$store.state.session.isMember", this.$store.state.session.isMember)
    if (this.$store.state.session.isMember) {
      let profile = this.$store.state.session.profile;
      this.userProfile = {
        userName: profile.name,
        email: profile.email,
        userId: profile.loginId,
      };
    }

    try {
      window.appleSetAppNo = (appNo) => {
        this.$store.commit("layout/setAppNo", {
          isAndroid: false,
          isApple: true,
          appNo
        });
      };
      window.webkit.messageHandlers.getAppNo.postMessage("");
    } catch (e) {
      //console.error(e);
    }

    setInterval(() => {
      try {
        let scripts = document.head.getElementsByTagName("script");
        scripts = [...scripts].filter(script => script.src == "https://cdn.megadata.co.kr/js/socialLink/social_sns_config_min.js");
        if (scripts.length > 0) {
          scripts.forEach(script => document.head.removeChild(script));
        }
      } catch (e) {
        // Mobon cleanup failed
        console.error(e);
      }

      try {
        let scripts = document.head.getElementsByTagName("script");
        scripts = [...scripts].filter(script => script.src == "https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js");
        if (scripts.length > 0) {
          scripts.forEach(script => document.head.removeChild(script));
        }
      } catch (e) {
        // Mobon cleanup failed
        console.error(e);
      }
    }, 100);

    this.$nextTick(() => {
      this.$recent.get();
    });

    const mobonException = ["/cart", "/goods", "/order/complete"];

    //console.log("Router path", this.$route.path);
    if (mobonException.indexOf(this.$route.path) < 0) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.executeMobon();
        }, 0);
      });
    }


    // const criteoException = ["/cart", "/goods", "/order/complete", "/gift/complete", "/main/best", "/main/new", "/search", "/category"];
    // if(criteoException.indexOf(this.$route.path) < 0) {
    //   this.excuteCriteo();
    // }
    // this.$nextTick(() => {
    //   // Scroll to top
    //   window.scrollTo(0, 0);
    //   // Remove opened modals
    //   try {
    //     let el = document.getElementById("modals-container");
    //     el.innerHTML = "";
    //   } catch (e) {
    //     // Error occurred
    //   }
    // });

    this.$router.afterEach((to, from) => {
      try{
        if (this.$store.state.session.isGuest) {
          /*
          if(typeof window != undefined){
            alert("LOGIN_REDIRECT : " + this.lastFromPage + ", QUERY : " + JSON.stringify(this.lastFromPageQuery || {}) );
          }
          */
         try  {
           let lastPath = from.path || '';
           let redirectExcept = /join|login|my-page|conversion|redirect/;
           if( !lastPath.match(redirectExcept) ){
             this.$cookies.set("LOGIN_REDIRECT", lastPath);
             this.$cookies.set("LOGIN_REDIRECT_QUERY", (from.query || {}) );
           }
         } catch (e){}
        }

      } catch (e){
      }
      this.$nextTick(() => {
        //window.scrollTo(0, 0);

        try {
          let el = document.getElementById("modals-container");
          el.innerHTML = "";
        } catch (e) {
          // Error occurred
        }
      });
    });

    
    //[앱다운 토스트팝업]
    setTimeout(() => {
      if (!this.$store.state.layout.isApp && (this.$device.isAndroid || this.$device.isIos)) {
        //let val = window.sessionStorage.getItem("NICECLAUP_APP_DOWNLOAD_POPUP");
        let val = getCookie("NICECLAUP_APP_DOWNLOAD_POPUP");
        if (val != "TRUE") {
          this.showAppDownloadPopup = true;
          document.body.style.overflow = 'hidden';
        }
      }
    }, 5000);
    

    try {

      setTimeout(() => {
      let muuid = getCookie("muuid");

      if(muuid && muuid != '') {
        setCookie("muuid", muuid, 2);
      } else {
        muuid = uuidv4();
        setCookie("muuid", muuid, 2);
      }

      // 현재 브랜드몰에서 재진입했는지 외부에서 들어왔는지 체크
      let checkReferrer = document.referrer;
      if(!checkReferrer) checkReferrer = '';

      checkReferrer = checkReferrer.replace('https://','');
      checkReferrer = checkReferrer.replace('http://','');

      var siteUrl = this.$env.siteUrl;
      siteUrl = siteUrl.replace('https://','');
      siteUrl = siteUrl.replace('http://','');

      // 브랜드몰 내부에서 이동해서 왔는지 확인후 내부이동이면 이후로직 무시함
      let mallRegExp = new RegExp('^' + siteUrl);
      let kcpRegExp = /testsmpay.kcp.co.kr|rsmpay.kcp.co.kr/;
      let membersRegExp = /testmembers.lpoint.com:500|members.lpoint.com/;
      let snsRegExp = /nid.naver.com/;

      if(!checkReferrer.match(mallRegExp) && !checkReferrer.match(kcpRegExp) && !checkReferrer.match(membersRegExp) && !checkReferrer.match(snsRegExp) ) {
        // 브랜드몰 내부 자체 이동이 아닐경우만
        if (typeof window != 'undefined' && typeof document != 'undefined') {
          let referrer = document.referrer;
          if(!referrer) referrer = '';

          window.sessionStorage.setItem("REFERRER", referrer);

          //alert("location : " + window.location.href);
          var url = new URL(window.location.href);
          var searchParams = url.searchParams;
          var utmSource = searchParams.get('utm_source');
          //alert("utmSource : " + utmSource);
          if(!utmSource) {
            utmSource = '';
          }

          if( !utmSource && referrer && referrer.split('\/').length >= 3 ) {
            let referrerSplit = referrer.split('\/');
            utmSource = referrerSplit[2];
          }

          window.sessionStorage.setItem("UTM_SOURCE", utmSource);

          let regInsertTargetPath = /^\/main\/today|^\/main\/new|^\/main\/best|^\/category|^\/goods|^\/event|^\/promotion/;

          if( (this.$route.path || '').match(regInsertTargetPath) ){
            //(메인/리스트/상품상세/기획전/이벤트) 일경우 저장

            if(referrer != null) {
              let device = '00';

              if(this.$device.isMobile) {
                if (this.$store.state.layout.isApp) {
                  if(this.$device.isAndroid) {
                    device = '50'
                  } else if(this.$device.isIos){
                    device = '60';
                  }
              }else{
                if (this.$device.isAndroid) {
                    device = '30'
                  } else if (this.$device.isIos) {
                    device = '40';
                  }else{
                    device = '20';
                  }
              }

              } else if (this.$device.isDesktop){
                device = '10';
              }

              const userAgent = navigator.userAgent;

              let params = {
                referrer : referrer
                , utmSource : utmSource ? utmSource : null
                , device : device
                , ssnId : muuid
                , userAgent : userAgent
                , fullPath : window.location.href
              }

              this.$axios.$post("/api/member/referrer", params);
              window.sessionStorage.removeItem("REFERRER");
            }

          }

        }

      }
      }, 3000);

    } catch (e) {
      alert("error : " + e.message);
    }


    this.$nextTick(async () => {
    if(["/login/naver","/login/apple"].indexOf(this.$route.path) < 0) {
      if(this.$device.isMobile && getCookie("AUTO_LOGIN") == "Y" ) {
        let lazyTime = 0;
        if(this.$route.path == '/login'){
          lazyTime = 0;
        }
        setTimeout(async () => {
          let rnwTkn = this.$cookies.get(process.env.SSO_RNW_TKN_KEY) || '';  // 갱신토큰 (쿠키에서 가져온다.)

          if (process.env.LMEMBERS_SSO_JOIN_YN == "Y" && rnwTkn) {
            try {
              this.doLMembersSsoAutoLogin(rnwTkn, this.autoLogin);
            } catch (error) {
              // console.error(error)
            }
          } else {
            this.autoLogin();
          }
        }, lazyTime);
      } else {
        setCookie("AUTO_LOGIN", "N", 0);
        /**
         * SSO 로그인은 오토로그인이 아닐 경우에만 실행한다.
         * 오토로그인이랑 호출이 겹치면 오토로그인 콜백이 안들어온다.
         */
        // SSO Login
        setTimeout(async() => {
          if (process.env.LMEMBERS_SSO_JOIN_YN == "Y" && this.$store.state.session.isGuest) {
            try {
              let isExceptPath = /join|login|my-page|conversion/;
              if(!this.$route.path.match(isExceptPath)) {
                let ssoTkn = common.cfGetValue(this.$route.query.ssoTkn);
                this.doLMembersSsoLogin(ssoTkn);
              }
            } catch (error) {
              console.log('[sso login error]', error)
            }
          }
        }, 0)
      }
    }
   });

  },

  beforeDestroy() {
    try {
      document.getElementById("mobon").remove();
    } catch (e) {
    }
  },

  methods: {
    async autoLogin() {
      this.$nextTick(async () => {
        //setTimeout(async () => {

          if( this.$store.state.session.isGuest ) {
            let randomParam = uuidv4();
            let profile = await this.$axios.$get("/api/user/me");
            this.$store.commit("session/set", profile);

            if(!this.$store.state.session.isGuest) return;

            return await this.$axios
                .$post(`/api/remember/login?ruuid=${randomParam}`, {uuid: randomParam}, {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-App-No": this.$store.state.layout.appNo,
                    "X-App-YN": this.$store.state.layout.isApp ? "Y" : "N",
                    "Cache-Control": "no-cache, no-store, must-revalidate"
                  }
                })
                .then(async (response) => {
                  let data = (response || {});
                  if(data.type= 'AUTO_LOGIN' && data.code == 'SUCCESS_LOGIN'){
                    await this.afterLoginHandler(true);
                    let profile = await this.$axios.$get("/api/user/me");
                    this.$store.commit("session/set", profile);
                    setCookie("AUTO_LOGIN", "Y", 360);
                  } else {
                    setCookie("AUTO_LOGIN", "N", 0);
                  }
                })
                .catch(async (err) => {
                  setCookie("AUTO_LOGIN", "N", 0);
                })
                ;
          }

        //}, 2000);
      });
    },

    executeMobon() {
      if (this.$env.IS_LIVE != 'true') return;
      try {
/*
let script = `
let params = {
  device: '${this.$device.isMobile ? 'M' : 'W'}',
  domain: '${this.getSiteDomain()}',
  referrer: '${this.getReferrer()}',
  referrerDomain: '${this.getReferrerDomain()}'
};

(function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.async=!0;n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");
enp('create', 'common', 'lottegfr02', params);  // W:웹, M: 모바일, B: 반응형
enp('send', 'common', 'lottegfr02');
// console.log("Execute Mobon Page View");
        `;
        eval(script);
*/
        //eval주석
        let params = {
          device: this.$device.isMobile ? 'M' : 'W',
          domain: this.getSiteDomain(),
          referrer: this.getReferrer(),
          referrerDomain: this.getReferrerDomain()
        };

        (function(a,g,e,n,t){a.enp=a.enp||function(){(a.enp.q=a.enp.q||[]).push(arguments)};n=g.createElement(e);n.defer=!0;n.src="https://cdn.megadata.co.kr/dist/prod/enp_tracker_self_hosted.min.js";t=g.getElementsByTagName(e)[0];t.parentNode.insertBefore(n,t)})(window,document,"script");
        enp('create', 'common', 'lottegfr02', params);  // W:웹, M: 모바일, B: 반응형
        enp('send', 'common', 'lottegfr02');
      } catch (e) {
        //console.error(e);
      }
    },

    // excuteCriteo() {
    //   try {
    //     //criteo 스크립트 실행
    //     if (typeof window !== "undefined") {
    //       window.criteo_q = window.criteo_q || [];
    //       var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";\][']
    //       window.criteo_q.push(
    //       { event: "setAccount", account: 95151},
    //       { event: "setEmail", email: "" },
    //       { event: "setZipcode", zipcode: "" },
    //       { event: "setSiteType", type: deviceType},
    //       { event: "viewHome"});
    //     }
    //   } catch (e) {}

    // },


    closeAppDownloadPopup() {
      //window.sessionStorage.setItem("NICECLAUP_APP_DOWNLOAD_POPUP", "TRUE");
      setCookie("NICECLAUP_APP_DOWNLOAD_POPUP", "TRUE", 1);
      this.showAppDownloadPopup = false;
      document.body.style.overflow = '';
    },

    startMove(event) {
        this.startY = event.touches[0].clientY - this.currentY;
        event.target.closest('.app_down').style.webkitAnimationFillMode  = 'none';
        event.target.closest('.app_down').style.animationFillMode  = 'none';
      },
      move(event) {
        this.currentY = event.touches[0].clientY - this.startY;
        if(this.currentY > 0){
          event.target.closest('.app_down').style.transform = `translateY(${this.currentY}px)`;
        }else{
          this.currentY = 0;
        }
      },
      endMove(event) {
        // Handle the end of the move if necessary
        if(this.currentY > 200){
          // 현업 요청으로 슬라이드로 닫았을때는 다시 켜지게 하기위해서 세션스토리지에 추가하지않음
          this.showAppDownloadPopup = false;
          document.body.style.overflow = '';
        }else{
          this.currentY = 0;
          event.target.closest('.app_down').style.transform = `translateY(${this.currentY}px)`;
        }
      }
  }
};

var setCookie = function(name, value, exp) {
  var date = new Date();
  date.setTime(date.getTime() + (exp*24*60*60*1000) );
  document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
};

var getCookie = function(name) {
  var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return value? value[2] : null;
};
</script>
<style scoped>

</style>