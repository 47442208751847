<template>
  <footer class="footer_wrap">
    <div class="wrapper">
      <ul class="footer_menu">
        <li><nuxt-link to="/footer/privacypolicy" class="privacy">개인정보처리방침</nuxt-link></li>
        <li><nuxt-link to="/footer/terms">이용약관</nuxt-link></li>
        <li><a target="_blank" href="https://www.lottegfr.co.kr/">회사소개</a></li>
        <li><a href = "javascript:;" onclick = "document.getElementById('light').style.display='block';document.getElementById('fade').style.display='block'">FAMILY SITE+</a></li>
        <!-- <li><a href="#" @click.prevent="memberBenefit()">L.POINT 통합 회원 혜택</a></li> -->
      </ul>
      <div id="light" class="pop_family">
        <a target="_blank" href="https://www.niceclaup.co.kr/">NICE CLAUP</a><br />
        <a target="_blank" href="https://www.canadagoose.co.kr/">CANADA GOOSE</a><br />
        <a href="javascript:;" onclick="document.getElementById('light').style.display='none';document.getElementById('fade').style.display='none'">FAMILY SITE-</a>
      </div>
      <ul class="footer_sns">
        <li><a target="_blank" href="http://www.facebook.com/kway.official" class="facebook">Facebook</a></li>
        <li><a target="_blank" href="https://www.instagram.com/kway_official" class="instagram">Instagram</a></li>
        <li><a target="_blank" href="http://www.youtube.com/KWay_official" class="youtube">Youtube</a></li>
        <li><a target="_blank" href="http://pf.kakao.com/_yKlxds" class="kakaoChannel">Kakao Channel</a></li>
      </ul>
      <div class="mgt08">
        <nuxt-link to="/footer/story" class="bt_white_gs">K-WAY STORY</nuxt-link>
        <nuxt-link to="/footer/store" class="bt_white_gs">STORE</nuxt-link>
        <!--nuxt-link to="/footer/" class="bt_white_gs">APP DOWNLOAD</nuxt-link-->
        <!--<nuxt-link to="/customer" class="bt_white_gs">CS CENTER</nuxt-link>-->
      </div>
      <ul class="footer_info">
        <li>{{ footerData.compNm }}</li>
        <li>상호명 : 롯데지에프알(주)</li>
        <li>대표 : {{ footerData.ceoNm }}</li>
        <li>주소 : {{ footerData.roadAddr }} {{ footerData.roadDtlAddr }}</li>
        <li>개인정보보호 책임자 : {{ footerData.pipOfcNm }}</li>
        <li>사업자등록번호 : {{ formatBizRegNo(footerData.bizNo) }}</li>
        <li>통신판매업신고번호 : {{ footerData.mosApprNo }}</li>
        <!--<li>고객센터 : {{ formatPhone(footerData.csTelNo) }}</li>
        <li>입금계좌 : 신한 140-013-410230 롯데지에프알(주)</li>-->
        <li class="copyright">
          COPYRIGHT(C) 2022 BY LOTTE GFR. ALL RIGHTS RESERVED.
        </li>
      </ul>
      <div class="foot_r_wrap">
        <dl>
          <dt>
            고객센터 {{ formatPhone(footerData.csTelNo) }}
            <div class="go_cs"><nuxt-link to="/customer/main">고객센터 바로가기 ></nuxt-link></div>
          </dt>
          <dd>상담시간 | 평일(월~금) 09:30 - 17:00 점심시간 : 12:30 - 13:30(토/일/공휴일 휴무)</dd>
          <dd>입금계좌 | 신한 140-013-410230 롯데지에프알(주)</dd>
          <dd>교환ㆍ반품주소 | 경기도 화성시 동탄산단 1길 4, 롯데지에프알 물류센터(우:18487)</dd>
          <dd>E-mail 주소 | gfrhelp@lotte.net</dd>
          <dd>FAX번호 | 02-6250-7899</dd>
        </dl>
      </div>
    </div>
  </footer>
</template>

<script>
import MemberBenefit from "@/components/commons/modal/MemberBenefit";
export default {
  name: "DesktopFooter",

  computed: {
    footerData() {
      return this.$store.state.session.site;
    }
  },
  methods: {
    memberBenefit() {
      this.$modal.show(MemberBenefit, {}, {
        width: this.$device.isMobile ? "100%" : "400px",
        height: "auto",
      });
    },
  }
};
</script>

<style scoped>
.pop_family {
  height: auto;
}
</style>