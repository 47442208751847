export default class LMemberService {
  constructor($axios) {
    this.$axios = $axios;
  }

  // SSO client 객체정보
  ssoClientInit() {    
    return this.$axios.$get('/api/lmembers/sso/clientInit');
  }

  // SSO 멤버스 ID 중복확인
  ssoUserIdDupYn(userId) {
    let data = {};
    data["userId"] = userId;
    
    let params = {
      params: data
    };
    return this.$axios.$get('/api/lmembers/sso/ssoUserIdDupYn', params);
  }

  // L.Point 조회
  pointOnlLmbrAuthInfo() {
    return this.$axios.$get('/api/lmembers/point/onlLmbrAuthInfo');
  }

  // L.Point 적립내역정보
  pointTranHistInfo(data) {
    let params = {
      params: data
    };
    return this.$axios.$get('/api/lmembers/point/tranHistInfo', params);
  }
  
  // L.point 적립예정 (상품)
  pointSaveAmtInfo(data) {
    let params = {
      params: data
    };
    return this.$axios.$get('/api/lmembers/point/pointSaveView', params);
  }

  // L.point 적립예정 (주문)
  pointSavePlanCalc(data) {
    let params = {
      params: data
    };
    return this.$axios.$get('/api/lmembers/point/pointSavePlanCalc', params);
  }
}